<template>
  <div>
    <el-container>
      <div v-if="type!='workflow'">
        <CommonTree
          :treeData="treeData"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          treeTitle="组织架构"
          :isShowdig="false"
          :showCheckbox="false"
          :expandOnClickNode="false"
          :treeExpand="false"
          :currentNodeKey="currentNodeKey"
          :defaultExpandedKeys="defaultExpandedKeys"
          nodeKey="id"
          :defaultCheckedKeys="defaultCheckedKeys"
          :loading="treeLoading"
          @getNodeClick="treeNodeClick"
        />
      </div>
      <el-main style="position: relative;height: calc(100vh - 94px)">
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="安全活动统计报表"
          @head-summary="headCollect"
          @head-update="headUpdate"
          @head-submit="headSubmit"
          @head-add="headAdd"
          @head-reportView="headReportView"
        >
          <template slot="left">
            <el-date-picker
              v-model="timeData"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              style="width: 200px"
              size="mini"
              :disabled="dateAndTimeDisabled"
              @change="selectList"
            />
          </template>
        </head-layout>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <head-layout
            head-title="基本信息"
          ></head-layout>
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="8">
              <el-form-item label="组织名称" prop="orgName">
                <el-input
                  v-model="ruleForm.orgName"
                  controls-position="right"
                  :disabled="true"

                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="数据状态" prop="dataState">
                <el-select v-model="ruleForm.dataState" disabled placeholder="请选择">
                  <el-option
                    v-for="item in dataState"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动类型" prop="actionType">
                <el-select v-model="ruleForm.actionType" placeholder="" disabled>
                  <el-option
                    v-for="item in actionType"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="填报日期" prop="updateTime">
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="ruleForm.updateTime"
                  type="date"
                  disabled="true"
                  style="width: 100%;"
                  placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <head-layout
            head-title='"零违章"人员信息'
          ></head-layout>
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="8">
              <el-form-item label='"零违章"个人总数' prop="monthPeopleCnt">
                <el-input
                  v-model="ruleForm.monthPeopleCnt"
                  controls-position="right"
                  :disabled="disabled"

                  maxlength="8"
                  show-word-limit
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.monthPeopleCntS<0 || ruleForm.monthPeopleCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.monthPeopleCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="其中一线员工数量" prop="monthFirstlinePeopleCnt">
                <el-input
                  v-model="ruleForm.monthFirstlinePeopleCnt"
                  controls-position="right"
                  :disabled="disabled"

                  maxlength="8"
                  show-word-limit
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.monthFirstlinePeopleCntS<0 || ruleForm.monthFirstlinePeopleCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.monthFirstlinePeopleCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="本年累计人数" prop="yearPeopleCnt">
                <el-input
                  v-model="yearPeopleCnt"
                  controls-position="right"
                  :disabled="true"
                  maxlength="8"
                  show-word-limit

                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.yearPeopleCntS<0 || ruleForm.yearPeopleCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.yearPeopleCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <head-layout
            head-title='"零违章"组织信息'
          ></head-layout>
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="8">
              <el-form-item label='"零违章"班组数量' prop="monthGroupCnt">
                <el-input
                  v-model="ruleForm.monthGroupCnt"
                  controls-position="right"
                  :disabled="disabled"

                  maxlength="8"
                  show-word-limit
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.monthGroupCntS<0 || ruleForm.monthGroupCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.monthGroupCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label='"零违章"工段数量' prop="monthWorkSectionCnt">
                <el-input
                  v-model="ruleForm.monthWorkSectionCnt"
                  controls-position="right"
                  :disabled="disabled"

                  maxlength="8"
                  show-word-limit
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.monthWorkSectionCntS<0 || ruleForm.monthWorkSectionCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.monthWorkSectionCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label='"零违章"车间/部门数量' prop="monthWorkshopCnt">
                <el-input
                  v-model="ruleForm.monthWorkshopCnt"
                  :disabled="disabled"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit

                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.monthWorkshopCntS<0 || ruleForm.monthWorkshopCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.monthWorkshopCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label='月度评选"零违章"组织数量' prop="monthOrgCnt">
                <el-input
                  v-model="monthOrgCnt"
                  controls-position="right"
                  :disabled="true"

                  maxlength="8"
                  show-word-limit
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.monthOrgCntS<0 || ruleForm.monthOrgCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.monthOrgCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="本年累计组织数" prop="yearOrgCnt">
                <el-input
                  v-model="yearOrgCnt"
                  controls-position="right"
                  :disabled="true"
                  maxlength="8"
                  show-word-limit

                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="ruleForm.yearOrgCntS<0 || ruleForm.monthPeopleCntS==null || false?'上次填报数据：0':'上次填报数据：' + ruleForm.yearOrgCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <head-layout
          head-title="下级组织安全活动统计信息"
          :head-btn-options="subordinateBtn"
          @head-reject="reject"
          @head-urge-processing="headUrging"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          class="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          class="safetActionStatSummary"
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          :cell-style="cellStyle"
        >
          <template #customBtn="{row}">
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="row.dataState == '4' && (ruleForm.dataState === '1' || ruleForm.dataState === '3')"-->
            <!--              @click="rowEdit(row)"-->
            <!--            > 编辑-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                v-if="row.dataState == '4' && (ruleForm.dataState === '1' || ruleForm.dataState === '3')"-->
            <!--                @click="reject(row)"-->
            <!--            > 驳回-->
            <!--            </el-button>-->
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      v-dialog-drag
      :title="'安全活动统计报表编辑'"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      v-if="showDialog"
      width="80%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="globalBoxClose"
    >
      <editDialog ref="editDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></editDialog>
    </el-dialog>
  </div>
</template>

<script>

import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CardLayout from "@/views/components/layout/card-layout";
import Template from "@/views/message/template/list";
import {
  summaryGetPage as selectPage,
  saveOrUpdate,
  detail as queryTheMonthlyProductionReportDetail,
  detailByBusiness,
  summary,
  summaryClickDataState, rejectSendingMessage
} from "@/api/report/safetActionStat";
import {dictionaryBiz} from "@/api/reportTasks";
import {detail as getProcessDetail, detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {reportTree, reportTreeAll} from "@/api/foreignReport/domesticReport";
import {mapGetters} from "vuex";
import {formatTime} from "@/util";
import editDialog from "@/views/business/reportManager/summaryReport/safetActionStatSummary/safetyDialog.vue";
import website from "@/config/website";
import {getDictionaryCode} from "@/api/system/dictbiz";
import {reviewAndUrging} from "@/api/reportReview";

export default {
  mixins: [exForm, draft],
  data() {
    return {
      orgCode: '',
      whether: true,
      fillDeptName: '',
      defaultExpandedKeys: [],
      currentNodeKey: '',
      treeShow: false,
      nodeId: '',
      actionType: '',
      ruleForm: {
        actionType: "ZV",
        dataState: "1",
        dataSource: "summary",
        dateAndTime: "",
        orgName: "",
        emergencDrillCnt: "",
        rectifiedHdCnt: "",
        majorHDCnt: "",
        hdCnt: "",
        checkCnt: "",
        trainTimes: "",
        safeInvest: "",
        safeWorkHours: "",
      },
      rules: {
        orgName: [
          {required: true, message: '请输入组织名称', trigger: ['blur']},
        ],
        monthPeopleCnt: [
          {required: true, message: '请输入"零违章"个人总数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],

        monthFirstlinePeopleCnt: [
          {required: true, message: '请输入其中一线员工数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],

        // monthOrgCnt: [
        //   {required: true, message: '请输入本月组织数', trigger: ['blur']},
        //   {pattern: /^[0-9]+$/, message: '请输入正整数'}
        // ],

        monthGroupCnt: [
          {required: true, message: '请输入"零违章"班组数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],

        monthWorkSectionCnt: [
          {required: true, message: '请输入"零违章"工段数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],

        monthWorkshopCnt: [
          {required: true, message: '请输入"零违章"车间/部门数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],

        // yearPeopleCnt: [
        //   {required: true, message: '请输入本年累计人数', trigger: ['blur']},
        //   {pattern: /^[0-9]+$/, message: '请输入正整数'}
        // ],
        //
        // yearOrgCnt: [
        //   {required: true, message: '请输入本年累计组织数', trigger: ['blur']},
        //   {pattern: /^[0-9]+$/, message: '请输入正整数'}
        // ],
      },
      existingYearPeopleCnt: 0,
      existingYearOrgCnt: 0,
      fullscreen: false,
      rowQuery: {},
      temporarySubmitData: {},
      dataForm: {},
      disabled: true,
      formLoading: false,
      form: {},
      dataState: [],
      query: {},
      // unitCodeList: "",
      processTemplateKey: "report_summary_safet_action_stat",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      dateAndTimeDisabled: false,
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      timeData: "",
      dicData: [],
      selectionList: [],
      defaultCheckedKeys: [],
      tabulateData: {},
      node: {}, //左侧树node
      saveButtonDisplay: false,
      type: undefined,
      searchTitle: "deptName",
      defaultProps: {
        label: "deptName",
        value: "id",
        key: "id",
      },
      showDialog: false,
      formType: '',
      formList: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "",
          prop: "dataState",
          align: "center",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          placeholder: "请选择状态",
        },
      ]
    };
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal && this.type != "workflow") {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    refresh() {
      return this.$store.state.common.report
    },
    monthOrgCnt() {
      let number = 0;
      const monthGroupCnt = this.ruleForm.monthGroupCnt && this.ruleForm.monthGroupCnt.toString().replace(/\D/g, '');
      const monthWorkSectionCnt = this.ruleForm.monthWorkSectionCnt && this.ruleForm.monthWorkSectionCnt.toString().replace(/\D/g, '');
      const monthWorkshopCnt = this.ruleForm.monthWorkshopCnt && this.ruleForm.monthWorkshopCnt.toString().replace(/\D/g, '');
      if (monthGroupCnt || monthWorkSectionCnt || monthWorkshopCnt) {
        number = (monthGroupCnt ? Number(monthGroupCnt) : 0)
          + (monthWorkSectionCnt ? Number(monthWorkSectionCnt) : 0)
          + (monthWorkshopCnt ? Number(monthWorkshopCnt) : 0);
      } else {
        number = 0;
      }
      this.ruleForm.monthOrgCnt = number;
      return number;
    },
    yearPeopleCnt() {
      let number = 0;
      const monthPeopleCnt = this.ruleForm.monthPeopleCnt && this.ruleForm.monthPeopleCnt.toString().replace(/\D/g, '');
      const existingYearPeopleCnt = this.existingYearPeopleCnt && this.existingYearPeopleCnt.toString().replace(/\D/g, '');
      if (monthPeopleCnt || existingYearPeopleCnt) {
        number = (monthPeopleCnt ? Number(monthPeopleCnt) : 0)
          + (existingYearPeopleCnt ? Number(existingYearPeopleCnt) : 0);
      } else {
        number = 0;
      }
      this.ruleForm.yearPeopleCnt = number;
      return number;
    },
    yearOrgCnt() {
      let number = 0;
      const monthOrgCnt = this.ruleForm.monthOrgCnt && this.ruleForm.monthOrgCnt.toString().replace(/\D/g, '');
      const existingYearOrgCnt = this.existingYearOrgCnt && this.existingYearOrgCnt.toString().replace(/\D/g, '');
      if (monthOrgCnt || existingYearOrgCnt) {
        number = (monthOrgCnt ? Number(monthOrgCnt) : 0)
          + (existingYearOrgCnt ? Number(existingYearOrgCnt) : 0);
      } else {
        number = 0;
      }
      this.ruleForm.yearOrgCnt = number;
      return number;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.whether) {
        if (this.ruleForm.dataState == "1" || this.ruleForm.dataState == "3") {
          buttonBtn.push(
            {
              label: "汇总",
              emit: "head-summary",
              type: "button",
              icon: "",
              btnOptType: "collect",
            });
          if (!this.saveButtonDisplay) {
            buttonBtn.push(
              {
                label: "修改",
                emit: "head-update",
                type: "button",
                icon: "",
                btnOptType: "delete",
              });
          }
          if (this.saveButtonDisplay) {
            buttonBtn.push(
              {
                label: "保存",
                emit: "head-add",
                type: "button",
                icon: "",
                btnOptType: "save",
              });
          }
        }
        if ((this.ruleForm.dataState == "1" || this.ruleForm.dataState == "3") && this.type != 'workflow') {
          buttonBtn.push(
            {
              label: "提交",
              emit: "head-submit",
              type: "button",
              icon: "",
              btnOptType: "submit",
            });
        }
        // if ((this.ruleForm.dataState != "1") && this.ruleForm.dataState != "3" && this.type != 'workflow') {
        //   buttonBtn.push(
        //       {
        //         label: "流程详情",
        //         emit: "head-process_detail",
        //         type: "button",
        //         icon: "",
        //         btnOptType: "loseEfficacy",
        //       });
        // }
        // if (this.ruleForm.dataState == "3" && this.type != 'workflow') {
        //   buttonBtn.push(
        //     {
        //       label: "重新汇总",
        //       emit: "head-process_detail",
        //       type: "button",
        //       icon: "",
        //       btnOptType: "loseEfficacy",
        //     });
        // }
      }
      if (this.type != 'workflow') {
        buttonBtn.push(
          {
            label: "报表查看",
            emit: "head-reportView",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }

      return buttonBtn;
    },
    subordinateBtn() {
      let result = [];
      if (this.dataForm.dataState != "4") {
        result.push(
          {
            label: "退回重新填报",
            emit: "head-reject",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });

        result.push(
          {
            label: "催办",
            emit: "head-urge-processing",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }
      return result;
    },
    tableOptions() {
      return {
        // index: true,
        // indexLabel: "序号",
        menuWidth: 150,
        selection: true,
        selectable: (row, index) => {
          return row.id != -1;
        },
        menu: false,
        linklabel: "companyName",
        column: [
          {
            label: "机构名称",
            prop: 'companyName',
            type: 'month',
            fixed: 'left',
            overHidden: true,
            width: 140,
          },
          {
            label: "填报状态",
            prop: "dataState",
            align: "center",
            type: "select",
            fixed: 'left',
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "统计月份",
            prop: 'dateAndTime',
            align: "center",
            overHidden: true,
            width: 90,
          },
          {
            label: "活动类型",
            prop: "actionType",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=action_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: '"零违章"个人总数"',
            prop: "monthPeopleCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "其中一线员工数量",
            prop: "monthFirstlinePeopleCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: '月度评选"零违章"组织数量',
            prop: "monthOrgCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: '"零违章"班组数量',
            prop: "monthGroupCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: '"零违章"工段数量',
            prop: "monthWorkSectionCnt",
            align: "right",
            width: 140,
            overHidden: true,
          }, {
            label: '"零违章"车间/部门数量',
            prop: "monthWorkshopCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本年累计人数",
            prop: "yearPeopleCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本年累计组织数",
            prop: "yearOrgCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "数据来源",
            prop: "dataSource",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_data_source",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建日期",
            prop: 'createTime',
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 180,
          },

          {
            label: "创建人",
            prop: "createName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "更新日期",
            prop: "updateTime",
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 180,
          },
          {
            label: "更新人",
            prop: "updateName",
            align: "center",
            width: 70,
            overHidden: true,
          },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    gridRowBtn() {
      return [];
    },
  },
  components: {
    editDialog,
    GridLayout,
    HeadLayout,
    CommonTree,
    CardLayout,
    Template
  },
  created() {
    let formDate = formatTime(new Date(), "yyyy-MM");
    this.timeData = formDate;
    this.query.dateAndTime = formDate;
    this.ruleForm.dateAndTime = formDate;
    let type = this.$route.query.type;
    this.type = type
    if (type !== 'workflow' || typeof type == "undefined") {
      // this.initTree()
    }
    dictionaryBiz("data_status_report").then((res) => {
      this.dataState = res.data.data;
    });
  },
  mounted() {
    this.query.actionType = 'ZV'
    // this.getProcessId()
    this.getDictionary();
    let type = this.$route.query.type;
    this.type = type
    if (this.type === 'workflow') {
      this.treeShow = false
      this.dateAndTimeDisabled = true
      let id = this.$route.query.id
      queryTheMonthlyProductionReportDetail(id).then((res) => {
        var data = res.data.data;
        this.ruleForm = data;
        this.ruleForm.orgName = this.ruleForm.companyName
        this.fillDeptName = this.ruleForm.companyName
        this.timeData = this.ruleForm.dateAndTime
        this.ruleForm.dataState = data.dataState
        this.temporarySubmitData = data
        this.query.dateAndTime = this.ruleForm.dateAndTime
        this.nodeId = data.unitCode
        if (data.existingYearPeopleCnt != -1) {
          this.existingYearPeopleCnt = data.existingYearPeopleCnt
        }
        if (data.existingYearOrgCnt != -1) {
          this.existingYearOrgCnt = data.existingYearOrgCnt
        }
        this.onLoad(this.page, {
          unitCode: data.unitCode,
          createUser: data.createUserID,
          templateCode: "safetActionStat"
        });
      })
    }
  },
  methods: {
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      const value = row.dataState;
      if (value && column.property) {
        if (value == '1' && column.property == 'dataState') {
          return {color: '#e6a23c'};
        } else if (value == '2' && column.property == 'dataState') {
          return {color: '#409eff'};
        } else if (value == '3' && column.property == 'dataState') {
          return {color: '#f56c6c'};
        } else if (value == '4' && column.property == 'dataState') {
          return {color: '#67c23a'};
        } else {
          return {}; // 默认情况下不设置特定的背景色
        }
      }
    },
    getDictionary() {
      getDictionaryCode('action_type').then((res) => {
        this.actionType = res.data.data;
      })
    },
    receiveData(row) {
      let templateCode = "safetActionStat";
      let type = "summary";
      this.$loading();
      let formDate = formatTime(row.statDate, "yyyy-MM");
      this.orgCode = row.fillDeptId;
      reportTree(templateCode, type, row.fillDeptId, formDate)
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id)
          })
          this.defaultExpandedKeys.push(row.fillDeptId)
          this.currentNodeKey = row.fillDeptId;
          this.nodeId = row.fillDeptId;
          this.fillDeptName = row.fillDeptName;
          this.ruleForm.orgName = row.fillDeptName;
          this.query.unitCode = this.nodeId;
          this.query.dateAndTime = row.statDate.substring(0, 7);
          this.timeData = row.statDate.substring(0, 7);
          this.onLoad(this.page);
          this.treeShow = true;
          this.treeLoading = false;
        })
        .catch((err) => {
          this.$loading().close();
          this.treeLoading = false;
        }).finally(() => {
        this.$loading().close();
      });
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      this.treeShow = true
      // this.onLoad(this.page);
    },
    rowView(row) {
      this.showDialog = true
      this.rowQuery = {
        row: JSON.stringify(row),
        type: 'view',
        nodeId: this.nodeId
      }
    },
    rowEdit(row) {
      this.showDialog = true
      this.rowQuery = {
        id: row.id,
        row: JSON.stringify(row),
        type: 'edit',
        nodeId: this.nodeId
      }
    },
    headUrging() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.dataState != "1" && item.dataState != "3");
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态为待提交或驳回！")
      } else {
        this.$loading()
        const result = this.selectionList.map(item => item.unitCode).join(',');
        let data = {deptId: result, reportCode: "safetActionStat"}
        reviewAndUrging(data)
          .then((result) => {
            if (result.data.code == 200) {
              this.$loading().close()
              this.onLoad(this.page);
              this.$message.success("催办成功!")
            }
          }).finally(() => {
          this.$loading().close()
        })
      }
    },
    reject() {
      if (this.selectionList.length !== 0) {
        let idList = [];
        this.selectionList.forEach((e) => {
          if (e.dataState != "4") {
            idList.push(e.id)
          }
        });
        if (idList.length === 0) {
          this.$prompt('请输入驳回原因:', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputType: "textarea",
            inputPattern: /^[\s\S]{1,200}$/, // 使用正则表达式限制长度在1到200个字符之间
            inputErrorMessage: '请输入1到200个字符的驳回原因', // 当输入不符合正则表达式时显示的错误信息
            closeOnClickModal: false
          }).then(({value}) => {
            let templateCode = "safetActionStat"
            this.$loading()
            rejectSendingMessage(this.selectionList, value, templateCode).then((res) => {
              if (res.data.code == 200) {
                this.initTree();
                this.onLoad(this.page);
                this.$message({
                  type: 'success',
                  message: '驳回成功'
                });
              }
            }).catch((e) => {
              this.$message({
                type: 'warning',
                message: '取消驳回'
              });
            }).finally(() => {
              this.$loading().close()
            })
          }).catch(() => {
            this.$message({
              type: 'warning',
              message: '取消驳回'
            });
          }).finally(() => {
            this.$loading().close()
          });
        } else {
          return this.$message.warning("请确保选择数据中全部是已通过!")
        }
      } else {
        return this.$message.warning("至少选择一条数据!")
      }
    },
    closeDialog() {
      this.showDialog = false;
      this.onLoad(this.page)
    },
    globalBoxClose() {
      this.onLoad(this.page);
    },
    isEmpty(value) {
      // 判断值是否为空
      return value === null || value === undefined || value === '';
    },
    removeEmptyFields(obj) {
      // 遍历对象的所有属性
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (this.isEmpty(obj[key])) {
            // 如果属性值为空，则删除该属性
            delete obj[key];
          }
        }
      }
      return obj;
    },
    queryTheMonthlyProductionReport(r, d) {
      let cc = {unitCode: this.nodeId, whetherTotalIsNeeded: "summation"}
      if (d) {
        this.ruleForm.dateAndTime = d
      }
      if (this.type === 'workflow') {
        cc = {
          unitCode: this.ruleForm.unitCode,
          whetherTotalIsNeeded: "summation",
          dateAndTime: this.timeData,
          actionType: "ZV"
        }
      } else {
        cc = {unitCode: this.nodeId, whetherTotalIsNeeded: "summation", dateAndTime: this.timeData, actionType: "ZV"}
      }
      if (!cc.unitCode) {
        return;
      }
      detailByBusiness(cc).then(res => {
        const {data} = res.data;
        //如果查询不到数据将所有的值置为初始值
        if (data == null || !data.dataState) {
          this.ruleForm = {}
          this.temporarySubmitData = {}
          this.ruleForm.dataState = "1"
          this.temporarySubmitData.dataState = "1"
          this.temporarySubmitData.dataSource = "summary"
          this.temporarySubmitData.actionType = "ZV"
          this.temporarySubmitData.unitCode = this.nodeId
          this.temporarySubmitData.companyName = this.node.deptName
          this.$refs.gridHeadLayout.searchForm.dateAndTime = undefined;
          this.onLoad(this.page, {unitCode: this.nodeId});
          if (data.existingYearPeopleCnt != -1) {
            this.existingYearPeopleCnt = data.existingYearPeopleCnt
          }
          if (data.existingYearOrgCnt != -1) {
            this.existingYearOrgCnt = data.existingYearOrgCnt
          }
          return
        }
        if (data.existingYearPeopleCnt != -1) {
          this.existingYearPeopleCnt = data.existingYearPeopleCnt
        }
        if (data.existingYearOrgCnt != -1) {
          this.existingYearOrgCnt = data.existingYearOrgCnt
        }
        this.ruleForm = this.removeEmptyFields(JSON.parse(JSON.stringify(data)));
        // this.ruleForm.dataState = data.dataState + ""
        this.temporarySubmitData = data
        this.ruleForm.orgName = this.fillDeptName
        if (r != "tree") {
          this.onLoad(this.page, {unitCode: this.nodeId});
        }
      })
    },
    /**
     * 汇总
     */
    headCollect() {
      if (this.timeData.length === 0) {
        this.$message({
          type: "warning",
          message: "请先选择月份"
        });
      } else {
        if (this.temporarySubmitData.id) {
          this.query.id = this.temporarySubmitData.id
        }
        if (this.type === 'workflow') {
          this.query.unitCode = this.ruleForm.unitCode
        } else {
          this.query.unitCode = this.nodeId
        }
        this.query.templateCode = "safetActionStat"
        this.query.whetherTotalIsNeeded = "summation"
        this.temporarySubmitData.dateAndTime = this.timeData
        summary(Object.assign(this.$refs.gridHeadLayout.searchForm, this.query)).then(res => {
          if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
            this.ruleForm = res.data.data
            this.updateSafetyProductionMonthly();
            this.timeData = this.temporarySubmitData.dateAndTime
            this.ruleForm.dateAndTime = this.temporarySubmitData.dateAndTime
            this.ruleForm.id = this.query.id
            this.ruleForm.createUser = this.temporarySubmitData.createUser
            this.ruleForm.createDept = this.temporarySubmitData.createDept
            this.ruleForm.unitCode = this.temporarySubmitData.unitCode
            this.ruleForm.companyName = this.temporarySubmitData.companyName
            this.ruleForm.actionType = 'ZV'
            this.ruleForm.dataState = this.temporarySubmitData.dataState
            this.ruleForm.dataSource = "summary"
            this.ruleForm.processInsId = this.temporarySubmitData.processInsId
            saveOrUpdate(this.ruleForm).then(res => {
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.queryTheMonthlyProductionReport("saveOrUpdate", res.data.data.dateAndTime);
            })
          } else {
            if (res.data.data == "noSubordinate") {
              this.$confirm("没有下级数据，无需汇总!", {
                showCancelButton: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning"
              })
            } else {
              this.$confirm(res.data.data, {
                showCancelButton: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning"
              })
            }
          }
        })
      }
    },
    updateSafetyProductionMonthly() {
      this.ruleForm.monthPeopleCntS = this.ruleForm.monthPeopleCnt
      this.ruleForm.monthFirstlinePeopleCntS = this.ruleForm.monthFirstlinePeopleCnt
      this.ruleForm.monthOrgCntS = this.ruleForm.monthOrgCnt
      this.ruleForm.monthGroupCntS = this.ruleForm.monthGroupCnt
      this.ruleForm.monthWorkSectionCntS = this.ruleForm.monthWorkSectionCnt
      this.ruleForm.monthWorkshopCntS = this.ruleForm.monthWorkshopCnt
      this.ruleForm.yearPeopleCntS = this.ruleForm.yearPeopleCnt
      this.ruleForm.yearOrgCntS = this.ruleForm.yearOrgCnt
    },
    /**
     * 保存
     */
    headAdd() {
      this.ruleForm.companyName = this.ruleForm.orgName
      this.ruleForm.unitCode = this.nodeId
      this.ruleForm.dateAndTime = this.timeData
      this.ruleForm.actionType = "ZV"
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          saveOrUpdate(this.ruleForm).then(res => {
            if (res.data.code == 200) {
              this.ruleForm = res.data.data;
              this.ruleForm.orgName = res.data.data.companyName
              this.ruleForm.dataState = String(res.data.data.dataState)
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
            }
          })
        }
      })
    },
    /**
     * 修改
     */
    headUpdate() {
      this.disabled = false
      this.saveButtonDisplay = true
    },
    initTree() {
      let templateCode = "safetActionStat";
      let type = "summary";
      this.treeLoading = true;
      reportTree(templateCode, type, this.orgCode, this.timeData)
        .then((result) => {
          this.treeData = result.data.data;
          if (this.treeData == "您不需要填报该报表!") {
            this.$message.warning("您不需要填报该报表!")
            return
          }
          this.node = this.treeData[0];
          if (this.type != 'workflow') {
            this.query.unitCode = this.nodeId;
          }
          if (this.currentNodeKey == '') {
            this.$nextTick(() => {
              this.defaultCheckedKeys.push(this.treeData[0].id)
            })
          }
          this.treeLoading = false;
          let cc = this.getNextLevelNodes(this.treeData, this.unitCode);
          let ids = [];
          var s = ""
          if (cc != null) {
            cc.forEach((ele) => {
              ids.push(ele.id);
            });
            s = ids.join(",");
          }
          this.page.currentPage = 1;
          // this.query.unitCodeList = s
          //查询详情
          this.queryTheMonthlyProductionReport("tree");
          // this.onLoad(this.page, {unitCode: this.nodeId});
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    getNextLevelNodes(tree, id) {
      let result = null; // 初始化为 null，表示未找到匹配节点
      (function recurse(nodes) {
        if (!nodes) return; // 若 nodes 为 null 或 undefined，直接返回
        nodes.forEach(node => {
          if (node.id === id) {
            // 找到匹配节点，将其子节点赋值给 result，并立即返回
            result = node.children || null; // 若子节点不存在，赋值为 null
            return;
          }
          if (node.children) {
            // 递归遍历子节点
            recurse(node.children);
          }
        });
      })(tree);
      return result;
    },
    /**
     * 点击日期选择器
     */
    selectList(res) {
      if (res == null) {
        return;
      }
      this.disabled = true
      this.saveButtonDisplay = false;
      this.page.currentPage = 1;
      this.query.dateAndTime = this.timeData;
      this.queryTheMonthlyProductionReport("dateSelection", this.query.dateAndTime)
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.whether = node.whether
      this.disabled = true
      this.saveButtonDisplay = false;
      this.node = node;
      this.nodeId = node.id;
      this.query.unitCode = this.nodeId;
      this.fillDeptName = this.node.deptName
      this.unitCode = this.nodeId;
      let cc = this.getNextLevelNodes(this.treeData, this.unitCode);
      let ids = [];
      var s = ""
      if (cc != null) {
        cc.forEach((ele) => {
          ids.push(ele.id);
        });
        s = ids.join(",");
      }
      this.page.currentPage = 1;
      // this.query.unitCodeList = s
      this.queryTheMonthlyProductionReport("treeNodeClick", this.timeData);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },

    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      // this.initTree()
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      if (this.query.dateAndTime == null || this.query.dateAndTime == undefined) {
        return;
      }
      this.$forceUpdate()
      this.page = page;
      this.crudLoading = true;
      if (this.type == "workflow") {
        params = {
          unitCode: this.ruleForm.unitCode,
          createUser: this.ruleForm.createUserID,
          templateCode: "safetActionStat"
        }
        this.query.type = "workflow"
      } else {
        this.query.whetherTotalIsNeeded = "summation"
      }
      this.query.dateAndTime = this.timeData
      delete this.query.unitCode
      try {
        if (this.$refs.gridHeadLayout.searchForm.dateAndTime != undefined && this.$refs.gridHeadLayout.searchForm.dateAndTime != null) {
          this.query.dateAndTime = this.$refs.gridHeadLayout.searchForm.dateAndTime;
        }
        if (this.type != "workflow") {
          params = {
            templateCode: "safetActionStat",
            unitCode: this.nodeId,
          }
          this.$refs.gridHeadLayout.searchForm.unitCode = this.nodeId
          this.$refs.gridHeadLayout.searchForm.templateCode = "safetActionStat"
        } else {
          var parts = this.query.dateAndTime.split("-");
          var year = parts[0];
          var month = parts[1];
          // 将月份转换为两位数
          if (month.length === 1) {
            month = "0" + month;
          }
          this.query.dateAndTime = year + "-" + month;
        }
        selectPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
        )
          .then((res) => {
            const data = res.data.data;
            this.$refs.gridLayOut.page.total = data.total;
            this.page.total = data.total;
            data.records.forEach(item => {
              if (item.dataState == -1) {
                item.dataState = null
              }
              item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHours
              item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
              item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
              item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
              item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
              item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
              item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
              item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
              item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
            })
            if (this.type != "workflow") {
              this.ruleForm.orgName = this.fillDeptName
            }
            this.tableData = data.records;
            const dateSourceMapping = {
              fill: 1,
              summary: 2,
              anhuan: 3,
              selfBuild: 4,
            };
            this.tableData.forEach(item => {
              item.dataSource = dateSourceMapping[item.dataSource] || item.dataSource;
            });
            this.tabulateData = this.tableData[0];
            this.$nextTick(() => {
              this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
            })
            this.crudLoading = false;
            this.selectionClear();
          })
      } catch (e) {
        this.crudLoading = false;
      }
      this.crudLoading = false
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headReportView() {
      if (this.nodeId) {
        if (this.timeData) {
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=safetActionStat&stat_month=" + this.timeData)
        } else {
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=safetActionStat")
        }
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    // 查看流程详情
    headProcessDetail() {
      const processInsId = this.ruleForm.processInsId;
      detail({processInsId: processInsId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param + '&isNewWindow=true';
        window.open(sUrl, "_blank");
      });
    },
    headSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            var row = this.ruleForm;
            if (row.dataState == 2 || row.dataState == 4) {
              return this.$message({
                type: "warning",
                message: "已提交或已通过的数据不可重复提交"
              });
            }
            let data = {
              dateAndTime: this.timeData,
              templateCode: "safetActionStat",
              unitCode: this.nodeId,
              actionType: "ZV"
            }
            summaryClickDataState(data).then(res => {
              if (res.data.code === 200) {
                if (res.data.data) {
                  this.$confirm("下级还有数据未通过，是否继续提交?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  }).then(() => {
                    this.$confirm("确定将提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    })
                      .then(() => {
                        this.rpTaskAttributeSubmit(row);
                      }).catch(() => {
                    })
                  });
                } else {
                  this.$confirm("确定将提交?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                    .then(() => {
                      this.rpTaskAttributeSubmit(row);
                    }).catch(() => {
                  })
                }
              }
            })
          } else {
            this.$message.warning("请先保存数据");
          }
        } else {
          this.$message.warning("当前报表数据有误，请先汇总数据或修改数据！");
        }
      })
    },
    rpTaskAttributeSubmit(data) {
      this.$loading();
      data.dataState = 4;
      saveOrUpdate(data).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.restRefresh();
          this.queryTheMonthlyProductionReport();
          this.initTree();
          this.disabled = true;
        }
      }).finally(() => {
        this.$loading().close();
      })
      // this.formProcess.id = data.id;
      // this.formProcess.deptId = this.nodeId
      // this.handleStartProcess5(true,true).then((res) => {
      //   let processInsId = res.data.data;
      //   data.processInsId = processInsId;
      //   data.dataState = 2;
      //   getProcessDetail({processInsId: processInsId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       data.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     saveOrUpdate(data).then((res) => {
      //       if (res.data.code === 200) {
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //         this.restRefresh();
      //         this.queryTheMonthlyProductionReport();
      //       }
      //     }).finally(() => {
      //       this.$loading().close();
      //     })
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.searchInput {
  width: 300px;
  position: absolute;
  top: 9px;
  right: 255px;
}

.head-picker ::v-deep .el-date-editor.el-input {
  width: 20% !important;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.demo-ruleForm {
  padding: 12px;
}

::v-deep .gridHeadLayout .el-button-group > .el-button:last-child {
  display: none !important;
}

.demo-ruleForm ::v-deep el-date-picker {
  width: 100% !important;
}

</style>


