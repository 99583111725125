<template>
  <div class="cardLayout">
    <div class="cardListBig" v-loading="cardLoading">
      <div class="cardList">
        <div class="cardBox" v-for="(item,index) in cardData" :key="index">
          <img class="cardImg" v-if="cardProps.imgUrl == 'courseCover' && item[cardProps.imgUrl] == ''" src="@/assets/training/trainingDefaultPicture.png">
          <img class="cardImg" v-else :src="item[cardProps.imgUrl]">
          <div class="cardTitle" :style="{color:themeColor}" @click="cardView(item)">{{item[cardProps.title]}}</div>
          <div class="cardTime">{{item[cardProps.time]}}</div>
          <div class="cardBtn">
            <slot :row="item"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="cardPagination">
      <el-pagination
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="page.currentPage"
        :page-sizes="page.pageSizes"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  computed:{
    ...mapGetters(['themeColor'])
  },
  props:{
    dataTotal: {
      type: Number,
      default: 0
    },
    cardLoading:{
      type: Boolean,
      default: true
    },
    page: {
      type: Object,
      default: () => ({
        pageSize: 20,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1,
        total: 0
      })
    },
    cardData: {
      type: Array
    },
    cardProps: {
      type: Object
    },
  },
  watch:{
    cardData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit("update:cardData", val)
      }
    },
  },
  methods:{
    // 查看
    cardView(item) {
      this.$emit("card-view", item)
    },
    currentChange(currentPage) {
      let page = {
        pageSize: this.page.pageSize,
        currentPage: currentPage,
        total: this.dataTotal
      }
      this.$emit('page-current-change', page)
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.$emit('page-size-change', this.page)
    },
  }
}
</script>
<style lang="scss" scoped>
.cardLayout {
  width: calc(100% - 24px);
  padding: 0 12px;
}

.cardListBig {
  width: 100%;
  height: calc(100vh - 278px);
  border: 1px solid #CCCCCC;
  margin-bottom: 12px;
  overflow: auto;
}

.cardList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cardPagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cardBox {
  width: calc(25% - 26px);
  border: 1px solid #CCCCCC;
  padding: 6px;
  border-radius: 5px;
  margin: 6px;
}

.cardImg {
  width: 100%;
  height: 180px;
}

.cardTitle {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin: 6px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 6px;
  cursor: pointer;
}

.cardTitle:hover {
  text-decoration: underline;
}

.cardTime {
  width: 100%;
  font-size: 12px;
  color: #333333;
  text-indent: 6px;
}

.cardBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
}
</style>
